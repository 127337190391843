import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Homepage from "./Pages/Homepage";
import SponsoredAdsPage from "./Pages/SponsoredAdsPage";
import PromotedAdsPage from "./Pages/PromotedAdsPage";
import PageNotFound from "./Pages/PageNotFound";
// import FooterPages from './Pages/FooterPages';
import AdsPolicies from "./Component/FooterPages/AdsPolicies";
import TermsOfUse from "./Component/FooterPages/TermsOfUse";
import TermAndCondition from "./Component/FooterPages/TermAndCondition";
import PrivacyPolicy from "./Component/FooterPages/PrivacyPolicy";
import Disclaimer from "./Component/FooterPages/Disclaimer";
import CookiePolicy from "./Component/FooterPages/CookiePolicy";
import UserAgreement from "./Component/FooterPages/UserAgreement";
import DataProtection from "./Component/FooterPages/DataProtection";
import LawsRegulation from "./Component/FooterPages/LawsRegulation";
import Help from "./Component/FooterPages/Help";
import Business from "./Component/categories/Business";
import UserForm from "./Component/UserForm";
import PromotedAds from "./Component/PromotedAds";
import Company from "./Component/FooterPages/Company";
import InternProgram from "./Component/FooterPages/InternProgram";
import Contact from "./Component/FooterPages/Contact";
import AboutUs from "./Component/FooterPages/AboutUs";
import CareerWithUs from "./Component/FooterPages/CareerWithUs";
import Blog from "./Component/FooterPages/Blog";
import AboutBusiness from "./Component/FooterPages/AboutBusiness";
import Developer from "./Component/FooterPages/Developer";
import NewAdsPage from "./Pages/NewAdsPage";
import BannerPage from "./Pages/BannerPage";
import ClassifiedAdsPage from "./Pages/ClassifiedAdsPage";
import PostEvents from "./Component/PostAds/PostEvents";
import PostBanner from "./Component/PostAds/PostBanner";
import PostAffiliate from "./Component/PostAds/PostAffiliate";
import InvestingPage from "./Pages/InvestingPage";
import FeatureAdsPage from "./Pages/FeatureAdsPage";
import { useEffect } from "react";
import FavoriteAdsPage from "./Pages/FavoriteAdsPage";
import AdsDetail from "./Component/DetailsPages/AdsDetail";
import ClasifiedsPage from "./Pages/ClasifiedsPage";
import FundingAdsPage from "./Pages/FundingAdsPage";
import AffiliateAdsPage from "./Pages/AffiliateAdsPage";
import CreateFunding from "./Component/CreateFunding";
import { useSelector } from "react-redux";
import AccountPage from "./Pages/AccountPage";
import EbayAds from "./Component/EbayAds";
import FavouriteAdsDetail from "./Component/DetailsPages/FavouriteAdsDetail";
import BusinessPage from "./Pages/BusinessPage";
import AllSearchResultPage from "./Pages/AllSearchResultPage";
import BlogDetail from "./Component/DetailsPages/BlogDetail";
import MyStore from "./Pages/MyStore";
import AffiliatePage from "./Pages/AffiliatePage";
import BookPage from "./Pages/BookPage";
import CategoryMenyPage from "./Pages/AllCategoryPage";
import BusinessStore from "./Pages/BusinessStore";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import CookieConsent from "react-cookie-consent";
import MyFeatureAdsPage from "./Pages/MyFeatureAdsPage";
import MyPromotedAdsPage from "./Pages/MySponsoredAdsPage";
import MyAffiliateAdsPage from "./Pages/MyAffiliateAdsPage";
import MyClassifiedAdsPage from "./Pages/MyClassifiedAdsPage";
import MyNewAdsPage from "./Pages/MyNewAdsPage";
import MyBannerPage from "./Pages/MyBannerPage";

const tokenExpirationMiddleware = () => {
  const token = localStorage.getItem("token");

  if (token) {
    const tokenPayload = JSON.parse(atob(token.split(".")[1]));
    const expirationTime = tokenPayload.exp * 1000;

    if (Date.now() > expirationTime) {
      localStorage.removeItem("token");
      return true; // Indicating the token is expired
    }
  }
  return false; // Token is valid or does not exist
};
const ProtectedRoute = ({ children }) => {
  const isTokenExpired = tokenExpirationMiddleware();

  if (isTokenExpired || !localStorage.getItem("token")) {
    return <Navigate to="/Login" />;
  }

  return children;
};

function App() {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { logIn, redirect } = useSelector((store) => store.auth);

  useEffect(() => {
    if (redirect) {
      navigate("/");
    }
  }, [redirect]);

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change
  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="I Accept"
        declineButtonText="Decline"
        cookieName="cookieConcent"
        style={{ background: "rgb(24 24 27)" }}
        containerClasses="p-4 bg-gray-800 text-white flex items-center justify-between"
        buttonClasses="ml-4 !bg-blue-500 hover:!bg-blue-600 !text-white font-semibold !py-2 !px-4 !rounded"
      >
        <div>
          <h1>Cookies used on the website!</h1>
          <span>
            This website uses cookies to ensure you get the best experience on
            our website.{" "}
            <a href="/help/ads-policies" className="text-blue-300">
              Find out more
            </a>
          </span>
        </div>
      </CookieConsent>

      <Routes>
        <>
          <Route path="/" Component={Homepage} />
          <Route path="/category-menu" Component={CategoryMenyPage} />
          <Route path="/sponsored-ads" Component={SponsoredAdsPage} />
          <Route path="/promoted-ads" Component={PromotedAdsPage} />
          <Route path="/classifieds-ads" Component={ClassifiedAdsPage} />
          <Route path="/new-ads" Component={NewAdsPage} />
          <Route path="/ebay-ads" component={EbayAds} />
          <Route path="/investment-category" Component={InvestingPage} />
          <Route path="/featured-ads" Component={FeatureAdsPage} />
          <Route path="/ads-detail/:slug" Component={AdsDetail} />
          <Route path="/favourite-ads/:id" Component={FavouriteAdsDetail} />
          <Route path="/affiliate-ads" Component={AffiliateAdsPage} />
          <Route path="/favorite-ads" Component={FavoriteAdsPage} />
          <Route
            path="/search-results/:searchValue"
            Component={AllSearchResultPage}
          />
          <Route path="/classified" Component={ClasifiedsPage} />
          <Route path="/affiliate" Component={AffiliatePage} />
          <Route path="/book" Component={BookPage} />
          {/* <Route path="/subscription" Component={Subscription} /> */}
          <Route path="/help/ads-policies" Component={AdsPolicies} />
          <Route path="/help/terms-of-use" Component={TermsOfUse} />
          <Route
            path="/help/terms-and-condition"
            Component={TermAndCondition}
          />
          <Route path="/help/privacy-policy" Component={PrivacyPolicy} />
          <Route path="/help/disclaimer" Component={Disclaimer} />
          <Route path="/help/cookie-policy" Component={CookiePolicy} />
          <Route path="/help/user-agreement" Component={UserAgreement} />
          <Route path="/help/data-protection" Component={DataProtection} />
          <Route path="/help/laws-regulations" Component={LawsRegulation} />
          <Route path="/help/help" Component={Help} />
          {/* About us  */}
          <Route path="/about/company" Component={Company} />
          <Route path="/about/business" Component={AboutBusiness} />
          <Route path="/about/intern-program" Component={InternProgram} />
          <Route path="/about/contact" Component={Contact} />
          <Route path="/about/about-us" Component={AboutUs} />
          <Route path="/about/career-with-us" Component={CareerWithUs} />
          <Route path="/about/developer" Component={Developer} />
          <Route path="/blog" Component={Blog} />
          <Route path="/blog/:id" Component={BlogDetail} />
          <Route path="/category/:slug" Component={Business} />

          {/* post new add buttons routes   */}
          {logIn ? (
            <Route
              path="/post/:slug/:id"
              element={
                <ProtectedRoute>
                  <PostEvents />
                </ProtectedRoute>
              }
            />
          ) : (
            <Route path="/post/:slug/:id" element={<UserForm />} />
          )}
          {logIn ? (
            <Route
              path="/postbanner"
              element={
                <ProtectedRoute>
                  <PostBanner />
                </ProtectedRoute>
              }
            />
          ) : (
            <Route path="/postbanner" element={<UserForm />} />
          )}
          {logIn ? (
            <Route
              path="/postaffiliate"
              element={
                <ProtectedRoute>
                  <PostAffiliate />
                </ProtectedRoute>
              }
            />
          ) : (
            <Route path="/postaffiliate" element={<UserForm />} />
          )}
          {logIn ? (
            <Route
              path="/my-featured-ads"
              element={
                <ProtectedRoute>
                  <MyFeatureAdsPage />
                </ProtectedRoute>
              }
            />
          ) : (
            <Route path="/my-featured-ads" element={<UserForm />} />
          )}
          {logIn ? (
            <Route
              path="/my-sponsored-ads"
              element={
                <ProtectedRoute>
                  <MyPromotedAdsPage />
                </ProtectedRoute>
              }
            />
          ) : (
            <Route path="/my-sponsored-ads" element={<UserForm />} />
          )}
          {logIn ? (
            <Route
              path="/my-affiliate-ads"
              element={
                <ProtectedRoute>
                  <MyAffiliateAdsPage />
                </ProtectedRoute>
              }
            />
          ) : (
            <Route path="/my-affiliate-ads" element={<UserForm />} />
          )}
          {logIn ? (
            <Route
              path="/my-classifieds-ads"
              element={
                <ProtectedRoute>
                  <MyClassifiedAdsPage />
                </ProtectedRoute>
              }
            />
          ) : (
            <Route path="/my-classified-ads" element={<UserForm />} />
          )}

          {logIn ? (
            <Route
              path="/my-new-ads"
              element={
                <ProtectedRoute>
                  <MyNewAdsPage />
                </ProtectedRoute>
              }
            />
          ) : (
            <Route path="/my-new-ads" element={<UserForm />} />
          )}
          {logIn ? (
            <Route
              path="/my-banner-ads"
              element={
                <ProtectedRoute>
                  <MyBannerPage />
                </ProtectedRoute>
              }
            />
          ) : (
            <Route path="/my-banner-ads" element={<UserForm />} />
          )}

          <Route path="/business-page" Component={BusinessPage} />
          <Route path="/" Component={Homepage} />
          <Route path="/Login" Component={UserForm} />
          <Route path="/PromotedAds" Component={PromotedAds} />
          {/* <Route path="/account" Component={UserAccount} /> */}
          <Route path="/account" Component={AccountPage} />

          {/* funding Page  */}
          <Route path="/quick-funding" Component={FundingAdsPage} />
          <Route path="/create-funding" Component={CreateFunding} />
          <Route path="/*" Component={PageNotFound} />
          <Route path="*" element={<UserForm />} />

          {/* store page  */}
          <Route path="/my-store" Component={MyStore} />
          <Route path="/store/:slug" Component={MyStore} />
          <Route path="/business-store" Component={BusinessStore} />
          <Route path="/business/:slug" Component={BusinessStore} />

          {/* Banner */}
          <Route path="/banner" Component={BannerPage} />
        </>
      </Routes>
    </>
  );
}

export default App;
